import React, { useEffect, useState } from 'react';
import '../../css/steps.css';
import { NavLink } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Onboarding_Seo from '../../SEO/Onboarding_Seo';

function Onboarding() {
       // Scroll to top function
       const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <Onboarding_Seo />
            <Header />
            {/* Main Body - Start================================================== */}
            <main className="page_content">
                {/* Page Header - Start================================================== */}
                <section className="page_header text-center bg-dark section_decoration overflow-hidden" style={{ backgroundImage: 'url("assets/images/backgrounds/page_header_bg_1.webp")' }}>
                    <div className="container">
                        <h1 className="page_title text-white">Onboarding</h1>
                        <ul className="breadcrumb_nav unordered_list justify-content-center">
                            <li><NavLink to="/help">Help</NavLink></li>
                            <li className="active"><NavLink to="/data-safety">KYC Upload</NavLink></li>
                        </ul>
                    </div>
                </section>
                {/* Page Header - End================================================== */}

                {/* FAQ Section - Start================================================== */}
                <section className="section_space kyc-upload">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="heading_block style_2 text-center">
                                    <h2 className="heading_text">
                                        KYC Documents Upload
                                    </h2>
                                    <p>
                                        On our mobile app to complete your onboarding process we require your Photo ID and a selfie. We use ID Scan solution which takes less than a minute to complete this for you.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div id="msform">
                                    {/* progressbar */}
                                    <ul id="progressbar">
                                        <NavLink to="/onboarding">
                                            <li className="active" id="confirm"><strong>1) Scanning Your Id</strong></li></NavLink>
                                        <NavLink to="/livenesscheck">
                                            <li id="confirm"><strong>2) Liveness Check</strong></li></NavLink>
                                        <NavLink to="/finish">
                                            <li id="confirm"><strong>Finish</strong></li></NavLink>
                                    </ul>
                                    {/* fieldsets */}
                                    <fieldset>
                                        <div className="form-card">
                                            <div className="row justify-content-center">
                                                <h4 className="mb-4">How To Scan Id?</h4>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/scanid1.png" alt="How to scan Id?" />
                                                        <div className="card-body">
                                                            <p className="card-text m-0">Try not to take the photo from any other angle. It will work regardless, but parallel is better.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/scanid2.png" alt="How to scan Id?" />
                                                        <div className="card-body">
                                                            <p className="card-text m-0">Try not to take the photo from any other angle. It will work regardless, but parallel is better.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="hrcss" />
                                            <div className="row justify-content-center">
                                                <h4 className="mb-4 mt-2">What to avoid?</h4>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/scanid4.png" alt=" What to avoid?" />
                                                        <div className="card-body">
                                                            <p className="card-text m-0">Please avoid flash or glares.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/scanid6.png" alt=" What to avoid?" />
                                                        <div className="card-body">
                                                            <p className="card-text m-0">Please avoid shadows over the ID.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/scanid7.png" alt=" What to avoid?" />
                                                        <div className="card-body">
                                                            <p className="card-text m-0">Please remove any background clutter.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/scanid3.png" alt=" What to avoid?" />
                                                        <div className="card-body">
                                                            <p className="card-text mb-4">Please make sure your details aren't blurry.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/scanid5.png" alt=" What to avoid?" />
                                                        <div className="card-body">
                                                            <p className="card-text m-0">Please make sure you are not cropping or skewing the ID.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <NavLink to="/livenesscheck" className="btn next-btn" onClick={scrollToTop}>Next</NavLink>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* FAQ Section - End================================================== */}
            </main>
            {/* Main Body - End================================================== */}
            <Footer />
        </>
    );
}

export default Onboarding
