import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Data_Safety_Seo from '../../SEO/Data_Safety_Seo';

function DataSafety() {

    return (
        <>
            <Data_Safety_Seo />
            <Header />
            {/* Main Body - Start================================================== */}
            <main className="page_content">
                {/* Page Header - Start================================================== */}
                <section className="page_header text-center bg-dark section_decoration overflow-hidden" style={{ backgroundImage: 'url("assets/images/backgrounds/page_header_bg_1.webp")' }}>
                    <div className="container">
                        <h1 className="page_title text-white">Data Safety</h1>
                        <ul className="breadcrumb_nav unordered_list justify-content-center">
                            <li><NavLink to="/help">Help</NavLink></li>
                            <li className="active"><NavLink to="/data-safety">Data Safety</NavLink></li>
                        </ul>
                    </div>
                </section>
                {/* Page Header - End================================================== */}

                {/* FAQ Section - Start================================================== */}
                <section className="section_space data-safty">
                    <div className="container">
                        <div className="heading_block style_2 text-center">
                            <h2 className="heading_text">
                                Data Safety
                            </h2>
                            <p>
                                If you have completed your registration with Riyo Remit but haven't initiated any transaction, then you can send us a request to delete your account with us. Deleting your account will remove your data we have stored with us.
                            </p>
                        </div>
                        <div className="row text-center justify-content-center">
                            <h3 className="mb-2">Temporary Account Deletion</h3>
                            <p className="mb-5">
                                Please follow the below steps to delete your account on a temporary basis.
                            </p>
                        </div>

                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                    <img className="card-img-top" src="assets/images/data-safety/1.png" alt="Data Safety" />
                                    <div className="card-body">
                                        <p className="card-text m-0">
                                            <strong>Step 1:</strong> Go to the dashboard and open
                                            the ‘Settings' tab. Click on the ‘Delete My Account' option.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                    <img className="card-img-top" src="assets/images/data-safety/2.png" alt="Data Safety" />
                                    <div className="card-body">
                                        <p className="card-text mb-4">
                                            <strong>Step 2:</strong> Click the ‘Yes' option to
                                            temporarily delete your account.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                    <img className="card-img-top" src="assets/images/data-safety/3.png" alt="Data Safety" />
                                    <div className="card-body">
                                        <p className="card-text m-0">
                                            <strong>Step 3:</strong> To proceed with your request, please click on
                                            the ‘Yes' option. We will delete all your data
                                            records stored in our system. You can cancel the request by clicking on
                                            the ‘No' option.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                    <img className="card-img-top" src="assets/images/data-safety/4.png" alt="Data Safety" />
                                    <div className="card-body">
                                        <p className="card-text mb-4">
                                            <strong>Step 4:</strong> Your request for account
                                            deletion will be submitted. Our team will reach out to you shortly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />
                        <div className="row text-center justify-content-center">
                            <h3 className="mb-2 mt-3">Permanent Account Deletion</h3>
                            <p className="mb-5">
                                Please follow the below steps to delete your account permanently.
                            </p>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                    <img className="card-img-top" src="assets/images/data-safety/1.png" alt="Data Safety?" />
                                    <div className="card-body">
                                        <p className="card-text m-0">
                                            <strong>Step 1:</strong> Go to the dashboard and open
                                            the ‘Settings' tab. Click on the ‘Delete My Account' option.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                    <img className="card-img-top" src="assets/images/data-safety/2.png" alt="Data Safety?" />
                                    <div className="card-body">
                                        <p className="card-text m-0">
                                            <strong>Step 2:</strong> Click on the ‘No. Delete my account
                                            permanently' option to delete your account
                                            permanently.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                    <img className="card-img-top" src="assets/images/data-safety/5.png" alt="Data Safety?" />
                                    <div className="card-body">
                                        <p className="card-text m-0">
                                            <strong>Step 3:</strong> To proceed with your request, please click on
                                            the ‘Yes' option. We will delete all your data
                                            records stored in our system.&nbsp;You can cancel the request by
                                            clicking on the ‘No' option.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0', borderRadius: 35 }}>
                                    <img className="card-img-top" src="assets/images/data-safety/4.png" alt="Data Safety?" />
                                    <div className="card-body">
                                        <p className="card-text mb-4">
                                            <strong>Step 4:</strong> Your request for account
                                            deletion will be submitted. Our team will reach out to you shortly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* FAQ Section - End================================================== */}
            </main>
            {/* Main Body - End================================================== */}
            <Footer />
        </>
    );
}

export default DataSafety
