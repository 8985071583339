import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { NavLink } from 'react-router-dom';

function CorridorsSlider() {
    const options = {
        loop: true,
        margin: 10,
        autoplay: false,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
            0: { items: 1 },
            600: { items: 3 },
            1000: { items: 4 }
        }
    };

    return (
        <div className="clients_logo_carousel">
            <div className="badge text-uppercase text-primary">Our Corridors</div>
            <OwlCarousel className="owl-theme" {...options}>
                <div className="item">
                   <NavLink to='/money-transfer-to-ethiopia'>
                   <div className="flag-div">
                        <div className="d-flex align-items-center">
                            <div className="flag-mr">
                                <img className="flag" src="images/flags/etb.png" alt="Money Transfer to Ethiopia" />
                            </div>
                            <div className="currency-code">
                                <h4>Ethiopia</h4>
                            </div>
                        </div>
                    </div>
                   </NavLink>                   
                </div>
                <div className="item">
                <NavLink to='/money-transfer-to-kenya'>
                   <div className="flag-div">
                        <div className="d-flex align-items-center">
                            <div className="flag-mr">
                                <img className="flag" src="images/flags/kes.png" alt="Money Transfer to Kenya" />
                            </div>
                            <div className="currency-code">
                                <h4>Kenya</h4>
                            </div>
                        </div>
                    </div>
                   </NavLink> 
                </div>
                <div className="item">
                <NavLink to='/money-transfer-to-somalia'>
                   <div className="flag-div">
                        <div className="d-flex align-items-center">
                            <div className="flag-mr">
                                <img className="flag" src="images/flags/sos.png" alt="Money Transfer to Somalia" />
                            </div>
                            <div className="currency-code">
                                <h4>Somalia</h4>
                            </div>
                        </div>
                    </div>
                   </NavLink> 
                </div>
                <div className="item">
                <NavLink to='/money-transfer-to-uganda'>
                   <div className="flag-div">
                        <div className="d-flex align-items-center">
                            <div className="flag-mr">
                                <img className="flag" src="images/flags/ugx.png" alt="Money Transfer to Uganda" />
                            </div>
                            <div className="currency-code">
                                <h4>Uganda</h4>
                            </div>
                        </div>
                    </div>
                   </NavLink> 
                </div>
                {/* Add more slides as needed */}
            </OwlCarousel>
        </div>
    )
}

export default CorridorsSlider
