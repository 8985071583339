import React from 'react';
import '../../css/steps.css';
import { NavLink } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Livenesscheck_Seo from '../../SEO/Livenesscheck_Seo';

function Livenesscheck() {
   // Scroll to top function
   const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};
    return (
        <>
            <Livenesscheck_Seo />
            <Header />
            {/* Main Body - Start================================================== */}
            <main className="page_content">
                {/* Page Header - Start================================================== */}
                <section className="page_header text-center bg-dark section_decoration overflow-hidden" style={{ backgroundImage: 'url("assets/images/backgrounds/page_header_bg_1.webp")' }}>
                    <div className="container">
                        <h1 className="page_title text-white">Onboarding</h1>
                        <ul className="breadcrumb_nav unordered_list justify-content-center">
                            <li><NavLink to="/help">Help</NavLink></li>
                            <li className="active"><NavLink to="/data-safety">KYC Upload</NavLink></li>
                        </ul>
                    </div>
                </section>
                {/* Page Header - End================================================== */}

                {/* FAQ Section - Start================================================== */}
                <section className="section_space kyc-upload">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="heading_block style_2 text-center">
                                    <h2 className="heading_text">
                                        KYC Documents Upload
                                    </h2>
                                    <p>
                                        On our mobile app to complete your onboarding process we require your Photo ID and a selfie. We use ID Scan solution which takes less than a minute to complete this for you.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div id="msform">
                                    {/* progressbar */}
                                    <ul id="progressbar">
                                        <NavLink to="/onboarding">
                                            <li className="active" id="confirm"><strong>1) Scanning Your Id</strong></li></NavLink>
                                        <NavLink to="/livenesscheck">
                                            <li className="active" id="confirm"><strong>2) Liveness Check</strong></li></NavLink>
                                        <NavLink to="/finish">
                                            <li id="confirm"><strong>Finish</strong></li></NavLink>
                                    </ul>
                                    {/* fieldsets */}
                                    <fieldset>
                                        <div className="form-card">
                                            <div className="row justify-content-center">
                                                <h4 className="mb-4">Taking Perfect Photo</h4>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/takephoto1.png" alt="Taking Perfect Photo" />
                                                        <div className="card-body">
                                                            <p className="card-text m-0">Make sure the camera has a consistent aspect ratio, so it does not stretch the image in any direction.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="hrcss" />
                                            <div className="row justify-content-center">
                                                <h4 className="mb-4 mt-2">What to avoid?</h4>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/takephoto7.png" alt=" What to avoid?" />
                                                        <div className="card-body">
                                                            <p className="card-text mb-0">Avoid shadows on the face or a strong side light.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/takephoto3.png" alt=" What to avoid?" />
                                                        <div className="card-body">
                                                            <p className="card-text m-0">Make sure your face is within the circle guidelines shown on the screen.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/takephoto4.png" alt=" What to avoid?" />
                                                        <div className="card-body">
                                                            <p className="card-text mb-0">Make sure you are in a well-lit room that is not too dark.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/takephoto2.png" alt="What to avoid?" />
                                                        <div className="card-body">
                                                            <p className="card-text m-0">Turn off any filters that might distort the image but keep the white light correction ON.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/takephoto6.png" alt=" What to avoid?" />
                                                        <div className="card-body">
                                                            <p className="card-text m-0 mb-4">Avoid having a light source behind the person's head, such as from a window.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/takephoto8.png" alt=" What to avoid?" />
                                                        <div className="card-body">
                                                            <p className="card-text m-0">Avoid anything covering the face, such as masks, hats, sunglasses or hair flopping over the face.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss" style={{ boxShadow: '0 5px 40px 0 rgba(0, 0, 0, .11)', margin: '0 0 20px 0' }}>
                                                        <img className="card-img-top" src="assets/images/photo/takephoto5.png" alt=" What to avoid?" />
                                                        <div className="card-body">
                                                            <p className="card-text m-0">For active liveness only - When performing actions such as turn right, do not over-rotate the face to 90 degrees, an angle of 35 degrees should be enough. Start with the camera raised so there is space to look down.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <NavLink to="/onboarding" type="button" className="btn prv-btn" onClick={scrollToTop}>Previous</NavLink>&emsp;
                                        <NavLink to="/finish" type="button" className="btn next-btn" onClick={scrollToTop}>Next</NavLink>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* FAQ Section - End================================================== */}
            </main>
            {/* Main Body - End================================================== */}
            <Footer />
        </>
    );
}

export default Livenesscheck
