import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Help_Seo from '../../SEO/Help_Seo';

function Help() {
    return (
        <>
            <Help_Seo />
            <Header />
            {/* Main Body - Start================================================== */}
            <main className="page_content">
                {/* Page Header - Start================================================== */}
                <section className="page_header text-center bg-dark section_decoration overflow-hidden" style={{ backgroundImage: 'url("assets/images/backgrounds/page_header_bg_1.webp")' }}>
                    <div className="container">
                        <h1 className="page_title text-white">Help</h1>
                        <ul className="breadcrumb_nav unordered_list justify-content-center">
                            <li><NavLink to="/">Home</NavLink></li>
                            <li className="active"><NavLink to="/help">Help</NavLink></li>
                        </ul>
                    </div>
                </section>
                {/* Page Header - End================================================== */}

                <section className="help-area section_space">
                <div className="container">
                    <div className="row align-items-center justify-content-center justify-content-lg-between">
                        <div className="col-lg-7">
                            <div className="title-area text-center text-lg-start">
                                <p>For Documents</p>
                            </div>
                        </div>
                    </div>
                    <div className="nav nav-tabs help-tabs" id="nav-tab" role="tablist">
                        <NavLink to="/onboarding" className="nav-link">
                            <img src="assets/images/photo/kyc.png" />
                            <span className="box-title">KYC Upload</span>
                        </NavLink>
                    </div>
                    <hr />
                    <div className="row align-items-center justify-content-center justify-content-lg-between">
                        <div className="col-lg-7">
                            <div className="title-area text-center text-lg-start">
                                <p>For Safety</p>
                            </div>
                        </div>
                    </div>
                    <div className="nav nav-tabs help-tabs" id="nav-tab" role="tablist">
                        <NavLink to="/data-safety" className="nav-link">
                            <img src="assets/images/photo/data-safety.png" />
                            <span className="box-title">Data Safety</span>
                        </NavLink>
                    </div>
                    <hr />
                    <div className="row align-items-center justify-content-center justify-content-lg-between">
                        <div className="col-lg-7">
                            <div className="title-area text-center text-lg-start">
                                <p>For FAQ's</p>
                            </div>
                        </div>
                    </div>
                    <div className="nav nav-tabs help-tabs" id="nav-tab" role="tablist">
                        <NavLink to="/onboarding-faq" className="nav-link">
                            <img src="assets/images/photo/onbordingfaq.png" />
                            <span className="box-title">Onboarding FAQs</span>
                        </NavLink>
                        <NavLink to="/general-faq" className="nav-link">
                            <img src="assets/images/photo/faq.png" />
                            <span className="box-title">General FAQs</span>
                        </NavLink>
                    </div>
                    <hr />
                    <div className="row align-items-center justify-content-center justify-content-lg-between">
                        <div className="col-lg-7">
                            <div className="title-area text-center text-lg-start">
                                <p>For Any Help</p>
                            </div>
                        </div>
                    </div>
                    <div className="nav nav-tabs help-tabs" id="nav-tab" role="tablist">
                        <NavLink to="https://api.whatsapp.com/send?phone=441217535521&amp;text=Hello There, I would like to enquire about money transfer." className="nav-link" target='_blank'>
                            <img src="assets/images/photo/whatsapp.png" />
                            <span className="box-title">Whatsapp</span>
                        </NavLink>
                        <NavLink to="tel:441217535521" className="nav-link">
                            <img src="assets/images/photo/phone.png" />
                            <span className="box-title">Phone</span>
                        </NavLink>
                    </div>
                </div>
                </section>
            </main>
            {/* Main Body - End================================================== */}
            <Footer />
        </>
    );
}

export default Help
