import { Route, Routes, useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";
import Home from "./Pages/Home/Home";
import Error from "./Pages/Error";
import ContactUs from "./Pages/Contact/ContactUs";
import GeneralFAQ from "./Pages/FAQ/GeneralFAQ";
import Help from "./Pages/Help/Help";
import Onboarding from "./Pages/Onboarding/Onboarding";
import Livenesscheck from "./Pages/Onboarding/Livenesscheck";
import Finish from "./Pages/Onboarding/Finish";
import OnboardingFAQ from "./Pages/FAQ/OnboardingFAQ";
import DataSafety from "./Pages/Data_Safety/DataSafety";
import Privacy_Policy from "./Pages/Privacy_Policy/Privacy_Policy";
import Complaint_Policy from "./Pages/Complaint_Policy/Complaint_Policy";
import Terms_and_Conditions from "./Pages/Terms_and_Conditions/Terms_and_Conditions";
import Money_Transfer_to_Ethiopia from "./Pages/Money_Transfer/Money_Transfer_to_Ethiopia";
import Money_Transfer_to_Uganda from "./Pages/Money_Transfer/Money_Transfer_to_Uganda";
import Money_Transfer_to_Somalia from "./Pages/Money_Transfer/Money_Transfer_to_Somalia";
import Money_Transfer_to_Kenya from "./Pages/Money_Transfer/Money_Transfer_to_Kenya";

function App() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Enables smooth scrolling
    });
  }, [pathname]);

  return (
    <div className="App">
      <Routes>
        <Route path="/*" element={<Error />} />
        <Route path="/" element={<Home />} />
        <Route path="/general-faq" element={<GeneralFAQ />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<Privacy_Policy />} />
        <Route path="/complaint-policy" element={<Complaint_Policy />} />
        <Route path="/terms-and-conditions" element={<Terms_and_Conditions />} />
        <Route path="/help" element={<Help />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/livenesscheck" element={<Livenesscheck />} />
        <Route path="/finish" element={<Finish />} />
        <Route path="/data-safety" element={<DataSafety />} />
        <Route path="/onboarding-faq" element={<OnboardingFAQ />} />
        <Route path="/money-transfer-to-ethiopia" element={<Money_Transfer_to_Ethiopia />} />
        <Route path="/money-transfer-to-kenya" element={<Money_Transfer_to_Kenya />} />
        <Route path="/money-transfer-to-somalia" element={<Money_Transfer_to_Somalia />} />
        <Route path="/money-transfer-to-uganda" element={<Money_Transfer_to_Uganda />} />
      </Routes>
    </div>
  );
}

export default App;
