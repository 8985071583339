import React from 'react';
import '../../css/steps.css';
import { NavLink } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Finish_Seo from '../../SEO/Finish_Seo';

function Finish() {
       // Scroll to top function
       const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <Finish_Seo />
            <Header />
            {/* Main Body - Start================================================== */}
            <main className="page_content">
                {/* Page Header - Start================================================== */}
                <section className="page_header text-center bg-dark section_decoration overflow-hidden" style={{ backgroundImage: 'url("assets/images/backgrounds/page_header_bg_1.webp")' }}>
                    <div className="container">
                        <h1 className="page_title text-white">Onboarding</h1>
                        <ul className="breadcrumb_nav unordered_list justify-content-center">
                            <li><NavLink to="/help">Help</NavLink></li>
                            <li className="active"><NavLink to="/data-safety">KYC Upload</NavLink></li>
                        </ul>
                    </div>
                </section>
                {/* Page Header - End================================================== */}

                {/* FAQ Section - Start================================================== */}
                <section className="section_space kyc-upload">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="heading_block style_2 text-center">
                                    <h2 className="heading_text">
                                        KYC Documents Upload
                                    </h2>
                                    <p>
                                        On our mobile app to complete your onboarding process we require your Photo ID and a selfie. We use ID Scan solution which takes less than a minute to complete this for you.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div id="msform">
                                    {/* progressbar */}
                                    <ul id="progressbar">
                                        <NavLink to="/onboarding">
                                            <li className="active" id="confirm"><strong>1) Scanning Your Id</strong></li></NavLink>
                                        <NavLink to="/livenesscheck">
                                            <li className="active" id="confirm"><strong>2) Liveness Check</strong></li></NavLink>
                                        <NavLink to="/finish">
                                            <li className="active" id="confirm"><strong>Finish</strong></li></NavLink>
                                    </ul>
                                    {/* fieldsets */}
                                    <fieldset>
                                        <div className="form-card">
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <div className="card cardcss">
                                                        <img className="card-img-top" src="assets/images/photo/finishimg.png" alt="Finish Image" />
                                                        <div className="card-body">
                                                            <p className="card-text m-0">On completion of your IDScan and Liveness check you will get a success screen to confirm completion of onboarding.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center">
                                                <div className="col-12 text-center">
                                                    <h6 className="purple-text text-center mt-3 mb-4">If you have any further questions then please visit our Onboarding FAQs page.</h6>
                                                </div>
                                                <div className='col-xl-6 col-lg-6 col-12'>
                                                    <NavLink to="/onboarding-faq" className="btn next-btn" onClick={scrollToTop}>FAQs</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* FAQ Section - End================================================== */}
            </main>
            {/* Main Body - End================================================== */}
            <Footer />
        </>
    );
}

export default Finish
