import React from 'react';
import { NavLink } from 'react-router-dom';
import Error_Seo from '../SEO/Error_Seo';


function Error() {
    return (
        <>
            <Error_Seo />
            {/* Main Body - Start================================================== */}
            <main className="page_content">
                {/* Error Section - Start
  ================================================== */}
                <section className="error_section text-center section_decoration">
                    <div className="container">
                        <div className="error_image">
                            <img src="assets/images/404_error_image.webp" alt="404 Error Image" />
                        </div>
                        <h1>Opps! Page not found</h1>
                        <p>
                        We're sorry, the page you have looked for does not exist in our database! Maybe go to our home page or try to use a search?
                        </p>
                        <div className="btns_group pb-0">
                            <NavLink className="btn bg-dark" to="/">
                                <span className="btn_label">Back to Home</span>
                                <span className="btn_icon"><svg width={20} height={16} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928932C12.9526 0.538408 12.3195 0.538408 11.9289 0.928932C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM0 9H19V7H0V9Z" fill="white" />
                                </svg></span>
                            </NavLink>
                        </div>
                    </div>
                </section>
                {/* Error Section - End ================================================== */}
            </main>
            {/* Main Body - End================================================== */}
        </>
    );
}

export default Error
