import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

function Header() {
    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <div>
            {/* Back To Top - Start */}
            <div className="backtotop" onClick={scrollToTop}>
                <NavLink to="#" className="scroll">
                    <i className="fa-solid fa-arrow-up" />
                </NavLink>
            </div>
            {/* Back To Top - End */}

            {/* Site Header - Start================================================== */}
            <header className="site_header header_layout_1">
                <div className="xb-header stricky">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-2 col-5">
                                <div className="site_logo">
                                    <NavLink className="site_link" to="/">
                                        <img src="assets/images/site_logo/riyoremit-logo.svg" alt="Site Logo White" />
                                        <img src="assets/images/site_logo/riyoremit-logo.svg" alt="Site Logo Black" />
                                    </NavLink>
                                </div>
                            </div>
                            <div className="col-lg-7 col-2">
                                <nav className="main_menu navbar navbar-expand-lg">
                                    <div className="main_menu_inner collapse navbar-collapse justify-content-lg-end" id="main_menu_dropdown">
                                        <ul className="main_menu_list unordered_list justify-content-end">
                                            <li className="dropdown">
                                                <NavLink className="nav-link" to="#" id="money_transfer" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Money Transfer
                                                </NavLink>
                                                <ul className="dropdown-menu" aria-labelledby="money_transfer">
                                                    <li>
                                                        <NavLink to="/money-transfer-to-ethiopia">Send Money to Ethiopia</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/money-transfer-to-kenya">Send Money to Kenya</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/money-transfer-to-somalia">Send Money to Somalia</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/money-transfer-to-uganda">Send Money to Uganda</NavLink>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <NavLink to="/help">Help</NavLink>
                                            </li>
                                            <li className='d-block d-sm-none'>
                                                <NavLink to="https://riyoremit.com/app/sign-up" target='_blank'>Sign Up</NavLink>
                                            </li>
                                            <li className='d-block d-sm-none'>
                                                <NavLink className="btn bg-primary text-dark" to="https://riyoremit.com/app/" target='_blank'>
                                                    Login
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            <div className="col-lg-3 col-5">
                                <ul className="btns_group ob-header-btn p-0 unordered_list justify-content-end">
                                    <li>
                                        <button className="mobile_menu_btn" type="button" data-bs-toggle="collapse" data-bs-target="#main_menu_dropdown" aria-expanded="false" aria-label="Toggle navigation">
                                            <i className="far fa-bars" />
                                        </button>
                                    </li>
                                    <li>
                                        <NavLink className="a" to="https://riyoremit.com/app/sign-up" target='_blank'>
                                            <span className="btn_label">Sign Up</span>
                                        </NavLink>
                                        <NavLink className="btn btn-outline-light active" to="https://riyoremit.com/app/" target='_blank'>
                                            <span className="btn_label">Login</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* Site Header - End================================================== */}

        </div>
    );
}

export default Header;
