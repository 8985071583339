import React from 'react';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { NavLink } from 'react-router-dom';
import Money_Transfer_to_Uganda_Seo from '../../SEO/Money_Transfer_to_Uganda_Seo';
import Calculator from '../../Components/Calculator';

function Money_Transfer_to_Uganda() {
  return (
    <>
      <Money_Transfer_to_Uganda_Seo />
      <Header />
      {/* Main Body - Start================================================== */}
      <main className="page_content">
        {/* Hero Section - Start================================================== */}
        <section className="hero_section hero_online_banking overflow-hidden section_decoration bg-dark" style={{ backgroundImage: 'url("assets/images/backgrounds/hero_bg_noise.webp")' }}>
          <div className="overlay" style={{ backgroundImage: 'url("assets/images/hero/hero_pattern.svg")' }} />
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7">
                <h1 className="hero_title text-white wow fadeInUp text-center" data-wow-delay=".2s">
                  Money Transfer to Uganda
                </h1>
                <div className="row d-none d-sm-block mt-4">
                  <div className="col-lg-12 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay=".3s">
                    <div className="iconbox_block">
                      <div className="iconbox_content">
                        <h3 className="iconbox_title text-white">
                          <img src="assets/images/icons/global.svg" alt="Icon Money Bag" /> Global Payments
                        </h3>
                        <p className="mb-0">
                          Join our global network of partners for easy deposits and withdrawals, providing an extensive and user-friendly experience.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay=".5s">
                    <div className="iconbox_block">
                      <div className="iconbox_content">
                        <h3 className="iconbox_title text-white">
                          <img src="assets/images/icons/safe-secure.svg" alt="Icon Mobile Network" /> Safe and Secure
                        </h3>
                        <p className="mb-0">
                          Our advanced data encryption technology ensures top-notch privacy, ensuring the security of your information.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay=".6s">
                    <div className="iconbox_block">
                      <div className="iconbox_content">
                        <h3 className="iconbox_title text-white">
                          <img src="assets/images/icons/pound-coin.svg" alt="Icon Mobile Network" /> Competitive Rates
                        </h3>
                        <p className="mb-0">
                          Our competitive pricing ensures you save more when you choose us over others.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <ul className="btns_group pb-0 unordered_list justify-content-lg-start mb-4 wow fadeInUp" data-wow-delay=".4s">
                  <li className='d-flex'>
                    <NavLink className="app-btn" to="#">
                      <img className='' src="assets/images/google_play.webp" alt='Google App' />
                    </NavLink>
                    <NavLink className="app-btn" to="#">
                      <img className='' src="assets/images/app_store.webp" alt='App Store App' />
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className='col-lg-5 mb-2'>
                <Calculator />
              </div>
            </div>
          </div>
          <div className="decoration_item icon_star_1">
            <img src="assets/images/icons/icon_star_4.svg" alt="Icon Star" />
          </div>
          <div className="decoration_item icon_star_2">
            <img src="assets/images/icons/icon_star_3.svg" alt="Icon Star" />
          </div>
          <div className="decoration_item shape_shadow_1">
            <img src="assets/images/shapes/shape_circle_2.svg" alt="Shape Shadow 1" />
          </div>
          <div className="decoration_item shape_shadow_2">
            <img src="assets/images/shapes/shape_circle_2.svg" alt="Shape Shadow 2" />
          </div>
          <div className="decoration_item shape_shadow_3">
            <img src="assets/images/shapes/shape_circle_2.svg" alt="Shape Shadow 3" />
          </div>
          <div className="decoration_item shape_shadow_4">
            <img src="assets/images/shapes/shape_circle_2.svg" alt="Shape Shadow 4" />
          </div>
        </section>
        {/* Hero Section - End================================================== */}


        {/* About Section - Start================================================== */}
        <section className="about_section section_space">
          <div className="container">
            <div className="row align-items-center justify-content-lg-between">
              <div className="col-lg-5">
                <div className="heading_block style_2">
                  <div className="badge text-uppercase text-dark bg-dark-subtle">Fast & Easy way to send money</div>
                  <h2 className="heading_text">
                    Trustworthy Money Transfer service
                  </h2>
                  <p className="heading_description ">
                    Our services offer a dependable money transfer solution to all of our clients. It offers a safe and secure way to transmit money overseas. The procedure is straightforward and easy to grasp. When transferring money anywhere in the world, you can rely on our money transfer service to ensure that it is delivered to the intended destination.
                  </p>
                  <ul className="iconlist_block unordered_list_block mb-5">
                    <li>
                      <span className="iconlist_icon">
                        <img src="assets/images/icons/icon_check_2.svg" alt="Check Icon" />
                      </span>
                      <span className="iconlist_text">
                      You pay what we display
                      </span>
                    </li>
                    <li>
                      <span className="iconlist_icon">
                        <img src="assets/images/icons/icon_check_2.svg" alt="Check Icon" />
                      </span>
                      <span className="iconlist_text">
                      No hidden fee or charges
                      </span>
                    </li>
                    <li>
                      <span className="iconlist_icon">
                        <img src="assets/images/icons/icon_check_2.svg" alt="Check Icon" />
                      </span>
                      <span className="iconlist_text">
                      Transparency in communication
                      </span>
                    </li>
                    <li>
                      <span className="iconlist_icon">
                        <img src="assets/images/icons/icon_check_2.svg" alt="Check Icon" />
                      </span>
                      <span className="iconlist_text">
                      Flexible, Easy & Safe
                      </span>
                    </li>
                  </ul>

                </div>

              </div>
              <div className="col-lg-6">
              <img src="assets/images/about/Uganda.png" alt="About Image 1" />
              </div>
            </div>
          </div>
        </section>
        {/* About Section - End================================================== */}

        {/* App Store Section - Start================================================== */}
        <section className="appstore_section section_decoration" style={{ backgroundImage: 'url("assets/images/backgrounds/app_store_bg_1.webp")' }}>
          <div className="container">
            <div className="row justify-content-lg-between">
              <div className="col-lg-6 order-lg-last">
                <div className="heading_block style_2">
                  <div className="badge text-uppercase text-dark">Download Our App</div>
                  <h2 className="heading_text">
                    Download And Enjoy The Experience
                  </h2>
                  <p className="heading_description mb-0 text-dark">
                    Send money worldwide in seconds with Riyo Remit app. Enjoy fast transfers, competitive rates, and secure transactions. Track your transfers easily, and all from a user-friendly app.
                  </p>
                </div>
                <div className='row'>
                  <div className='col-lg-6 col-6'>
                    <NavLink className="" to="#!">
                      <img className src="assets/images/google_play.webp" alt="Google App" />
                    </NavLink>
                  </div>
                  <div className='col-lg-6 col-6'>
                    <NavLink className="" to="#!">
                      <img className src="assets/images/google_play.webp" alt="Google App" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="app_image">
                  <img src="assets/images/about/mobile_app_image.webp" alt="App Image" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* App Store Section - End================================================== */}


      </main>
      {/* Main Body - End================================================== */}
      {/* Footer Section Here */}
      <Footer />
    </>
  );
}

export default Money_Transfer_to_Uganda
